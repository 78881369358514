.header {
  padding: 22px 23px 16px 23px;
  border-bottom: 1.5px solid #e7e6e4;
}

.subHeading {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #272522;
  opacity: 0.8;
  padding-left: 40px;
  margin-top: 8px;
}

.listWrap {
  padding: 23.5px 24px 48px 24px;
}

.msgBody {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 4px;
}

.editorRoot {
  border: none !important;
}

.eidtorWrapper {
  max-width: 100%;
  max-height: 20px !important;
  min-height: 20px !important;
  padding: 0 4px !important;
  overflow: hidden;
}

.desktopActionIcon,
.desktopActionIcon path {
  fill: #6d675f !important;
}

.mobileActionIcon {
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 768px) {
  .listWrap {
    padding: 16px 14.5px 32px 14.5px;
  }
}
